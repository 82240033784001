import { createTheme, ThemeProvider } from "@mui/material/styles"
import { Provider } from "react-redux"
import { Toaster } from "sonner"

import { getRootStore } from "store"
import { Homepage } from "./homepage"
import { store } from "./store"
import { GlobalStoreProvider } from "hooks"
import { useEffect } from "react"
import { useHref, useNavigate } from "react-router-dom"

const theme = createTheme({
  palette: {
    common: {
      black: "#1E3148",
      white: "#fff",
    },
    primary: {
      main: "#1E3148",
      contrastText: "#fff",
    },
    secondary: {
      main: "#EE6F42",
      dark: "#DB663D",
      contrastText: "#fff",
    },
    gray: {
      main: "#A4AFBD",
      dark: "#737C86",
      light: "#EDEDED",
    },
    text: {
      primary: "#1E3148",
      secondary: "#fff",
    },
  },
  typography: {
    fontFamily: "Rubik, sans-serif",
    fontSize: 16,
    fontWeightRegular: 400,
    button: {
      fontWeight: 400,
      textTransform: "unset",
      whiteSpace: "nowrap",
    },
  },
  shape: {
    borderRadius: 8,
  },
  components: {
    MuiInputBase: {
      styleOverrides: {
        root: {
          backgroundColor: "#fff",
          borderColor: "#EDEDED",
          "&:hover": {
            borderColor: "#A4AFBD",
          },
        },
      },
    },
    MuiButton: {
      variants: [
        {
          props: { color: "secondary" },
          style: {
            "&:hover": {
              boxShadow: "none",
            },
            padding: "12px 16px",
            boxShadow: "none",
          },
        },
        {
          props: { variant: "outlined" },
          style: {},
        },
      ],
    },
  },
})

const rootStore = getRootStore()
const accessToken = localStorage.getItem("accessToken")

export function App() {
  const navigate = useNavigate()
  const path = useHref()
  useEffect(() => {
    if (!accessToken) {
      if (
        !path.startsWith("/b/") &&
        !path.startsWith("/s/") &&
        !path.startsWith("/r/")
      ) {
        navigate("/login")
      }
    }
  }, [accessToken])

  // логика jivochat

  const jivo = document.querySelector("jdiv")

  useEffect(() => {
    if (jivo) {
      if (window.location.pathname !== "/feedback") {
        jivo.classList.add("hidden")
      } else {
        jivo.classList.remove("hidden")
      }
    }
  }, [window.location.pathname])

  return (
    <>
      <Provider store={store}>
        <GlobalStoreProvider store={rootStore}>
          <ThemeProvider theme={theme}>
            <Homepage />

            <Toaster
              position='top-center'
              toastOptions={{ duration: 3000}}
              richColors
              expand={false}
             
              style={{padding: 10}}
            />
          </ThemeProvider>
        </GlobalStoreProvider>
      </Provider>
    </>
  )
}