import React from "react"
import { Close } from "@mui/icons-material"
import { useEffect, useState } from "react"
import { Modal } from "shared/ui/modal/Modal"

const ChangeDate = ({
  active,
  setActive,
  text,
  id,
  loading,
  onClose,
  onConfirm,
  closeStatusModal,
  isSuccess,
  isError,
}) => {
  const [successActive, setSuccessActive] = useState(false)
  const [success, setSuccess] = useState(false)
  const [error, setError] = useState(false)

  const handleConfirm = () => {
    if (loading) return
    onConfirm()
  }

  useEffect(() => {
    if (isError) {
      setError(true)
    }
  }, [isError])
  useEffect(() => {
    if (isSuccess) {
      setSuccess(true)
    }
  }, [isSuccess])

  const handleClick = () => {
    onClose()
    window.location.reload()
  }
  return (
    <>
      <Modal
        active={active}
        setActive={setActive}
        onClose={onClose}
        className={`max-w-sm ${successActive ? "hidden" : "block"}`}
      >
        <div className='text-end'>
          <button
            onClick={onClose}
            className='text-gray hover:text-black h-fit'
          >
            <Close />
          </button>
        </div>
        <p className='text-darkGray text-center max-w-[250px] mx-[auto] my-5'>
          {text}
        </p>
        <div className='flex'>
          <button className='btnOutlined mr-5 flex-1' onClick={onClose}>
            Отмена
          </button>
          <button
            // className={
            //   loading
            //     ? "opacity-50 btnFilled flex-1"
            //     : "opacity-100 btnFilled flex-1"
            // }
            className={`btnFilled flex-1 ${loading ? "opacity-50 cursor-not-allowed" : "opacity-100"}`}
            disabled={loading}
            onClick={handleConfirm}
          >
            Да
          </button>
        </div>
      </Modal>

      {success && (
        <Modal
          active={success}
          onClose={onClose}
          className='max-w-sm  flex flex-col h-[170px] justify-between'
        >
          <p className='text-darkGray text-center max-w-[250px] mx-[auto] my-5'>
            Ваша запись успешно изменена!
          </p>
          <div className=' w-full'>
            <button
              className=' w-full text-right text-sm font-medium  '
              onClick={handleClick}
            >
              Ок
            </button>
          </div>
        </Modal>
      )}
      {error && (
        <Modal
          active={success}
          className='max-w-sm  flex flex-col h-[170px] justify-between'
        >
          <p className='text-darkGray text-center max-w-[250px] mx-[auto] my-5'>
            При изменении произошла ошибка
          </p>
          <div className=' w-full'>
            <button
              className=' w-full text-right text-sm font-medium  '
              onClick={handleClick}
            >
              Ок
            </button>
          </div>
        </Modal>
      )}
    </>
  )
}

export default ChangeDate;
