import { createSlice, createAsyncThunk } from "@reduxjs/toolkit"
import { currentUrl } from "../../url";

const accessToken = localStorage.getItem("accessToken")
interface IRawSphere {
  direction: {
    id: number;
    name: string;
  };
}
type ISphere = {
  direction: {
    id: number
    name: string
  }
}

interface ISpheresState {
  spheres: ISphere[]
  spheresLoading: boolean
  spheresError: boolean
}

export const fetchSpheres = createAsyncThunk<
  ISphere[],
  undefined,
  { rejectValue: boolean}
>("spheres/fetchSpheres", async function (_, { rejectWithValue }) {
  
  if (!accessToken) {
    return rejectWithValue(true); // Если нет accessToken, возвращаем ошибку
  }
  try {
    const res = await fetch(`${currentUrl}/master/directions`, {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    })

    if (!res.ok) {
      return rejectWithValue(true); // Если запрос завершился с ошибкой
    }


    const data = await res.json()
    const processedData: ISphere[] = data.map(
      (item:IRawSphere ): ISphere => ({
        direction: {
          id: item?.direction?.id,
          name: item?.direction?.name,
        },
      })
    )

    return processedData
  }
  catch (err) {
    return rejectWithValue(true); // Ловим любые исключения и возвращаем ошибку
  }
})

const initialState: ISpheresState = {
  spheres: [],
  spheresLoading: false,
  spheresError: false
}

const spheresSlice = createSlice({
  name: 'spheresSlice',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchSpheres.pending, (state) => {
        state.spheresLoading = true
        state.spheresError = false
      })
      .addCase(fetchSpheres.fulfilled, (state, action) => {
        state.spheres = action.payload
        state.spheresLoading = false
      })
      .addCase(fetchSpheres.rejected, (state) => {
        state.spheresError = true
      })
  }
})

export default spheresSlice.reducer
