import React from "react"
import { InputWithIcon } from "../../../shared/ui/input/InputWithIcon"
import { CalendarTodayOutlined, Search } from "@mui/icons-material"
import DatePicker from "react-datepicker"
import { TableSpinner } from "../../../shared/ui/dataTable/spinner"
import { TableFallback } from "../../../widgets/fallbacks/TableFallback"
import AnalyzesTable from "../../../entities/laboratory/analyzes/AnalyzesTable"
import { useGetAnalyzesQuery } from "./analyzesApi"
import { useNavigate } from "react-router-dom"
import { useState } from "react"
import { Period } from "entities/bookings/ui/forms/Period"

const formatDate = (date) => {
  const newDate = new Date(date)
  const year = newDate.getFullYear()
  const month = (newDate.getMonth() + 1).toString().padStart(2, "0") // +1, так как месяцы начинаются с 0
  const day = newDate.getDate().toString().padStart(2, "0")

  return `${year}-${month}-${day}`
}

export const Analyzes = () => {
  const today = new Date()
  const navagate = useNavigate()
  const [search, setSearch] = useState()
  const [startDate, setStartDate] = useState("")
  const [endDate, setEndDate] = useState("")
  const { data: analyzesData, isLoading: analyzesLoading } =
    useGetAnalyzesQuery({
      from: startDate && formatDate(startDate),
      to: endDate ? formatDate(endDate) : formatDate(startDate),
      search: search,
    })
  const analyzes = analyzesData?.results

  //тут логика на  период компонент
  const [showPeriod, setShowPeriod] = useState(false)
  const togglePeriod = () => {
    setShowPeriod((prev) => !prev)
  }

  return (
    <>
      <div className='sm:rounded-lg sm:bg-white  p-4 '>
        <div className='flex justify-center items-center mb-4'>
          <div className='w-full flex-1 mr-0 sm:mr-4'>
            <InputWithIcon
              value={search}
              onChange={(e) => setSearch(e.target.value)}
              type='text'
              placeholder={"Поиск по номеру и дате"}
              className='inputOutlined w-full'
              Icon={<Search fontSize='small' className='text-gray' />}
            />
          </div>
          <>
            <DatePicker
              className='hidden sm:flex inputOutlined mr-4'
              dateFormat='dd-MM-yyyy'
              placeholderText='Период от'
              selected={startDate}
              selectsStart
              startDate={startDate}
              endDate={endDate}
              onChange={(date) => setStartDate(date)}
            />
            <DatePicker
              className='hidden sm:flex inputOutlined mr-4'
              dateFormat='dd-MM-yyyy'
              minDate={startDate}
              placeholderText='Период до'
              selectsEnd
              startDate={startDate}
              endDate={endDate}
              selected={endDate}
              onChange={(date) => setEndDate(date)}
            />
          </>
          <button
            className='flex sm:hidden p-3 rounded-[6px]  items-center justify-center border border-[#EDEDED] mr-4 ml-4'
            onClick={togglePeriod}
          >
            <CalendarTodayOutlined color={"disabled"} />
          </button>

          {/*<button className="p-3 rounded-[6px] flex items-center justify-center border border-[#EDEDED] mr-4"*/}
          {/*        onClick={clearFilters}>*/}
          {/*  <FilterAltOffOutlinedIcon color={"disabled"} />*/}
          {/*</button>*/}
          {showPeriod && (
            <Period
              startDate={startDate}
              endDate={endDate}
              onChange1={(date) => setStartDate(date)}
              onChange2={(date) => setEndDate(date)}
              selected={endDate}
              minDate={startDate}
              active={showPeriod}
              setActive={setShowPeriod}
            />
          )}

          <button
            onClick={() => navagate("/analyzes/new")}
            className='btnFilled px-4 sm:flex hidden'
          >
            Добавить анализ
          </button>
        </div>
        <button
          onClick={() => navagate("/analyzes/new")}
          className='sm:hidden block btnAddContent fixed right-4 bottom-[80px] z-10'
        >
          +
        </button>

        {/*{activeNewBooking && (*/}
        {/*  <AddBooking active={activeNewBooking} setActive={setActiveNewBooking} />*/}
        {/*)}*/}

        {analyzesLoading ? (
          <div className='w-full min-h-[300px] flex justify-center items-center'>
            <TableSpinner />
          </div>
        ) : analyzes.length > 0 ? (
          <AnalyzesTable analyzes={analyzes} />
        ) : (
          <TableFallback text={"Список анализов пуст"} />
        )}
      </div>
    </>
  )
}