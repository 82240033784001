import React, { useCallback, useEffect, useState } from "react"
import { Close } from "@mui/icons-material"
import { PhoneInput } from "shared/ui/input/PhoneInput"
import { Modal } from "shared/ui/modal/Modal"
import { useGetServicesQuery } from "pages/services/slice"
import { MenuItem } from "@mui/material"
import { SelectedServiceItem } from "../table/SelectedServicesItem"
import { SelectContained } from "../../../../shared/ui/select/SelectContained"
import { Controller, useForm } from "react-hook-form"
import {
  useGetCaledarMastersQuery,
  useGetMastersQuery,
} from "../../../../pages/masters/slice"
import { useSelector } from "react-redux"
import { TableSpinner } from "../../../../shared/ui/dataTable/spinner"
import { ErrorMsg } from "../../../../shared/ui/input/ErrorMsg"
import axios from "axios"
import { currentUrl } from "../../../../url"
import {
  useDeleteServicesMutation,
  useGetOneBookingQuery,
} from "../../../../pages/bookings/newBookingApi"
import AccessTimeIcon from "@mui/icons-material/AccessTime"
import DatePicker from "react-datepicker"
import { duration } from "moment"
import dayjs from "dayjs"
import { toast } from "sonner"

const accessToken = localStorage.getItem("accessToken")

const user = JSON.parse(localStorage.getItem("user"))
const salonId = user?.salon_id
const startTime = user?.salon?.startWork
const endTime = user?.salon?.endWork

const titles = ["Услуга", "Специалист", "Цена", "Продолжительность", ""]

const formatDate = (date) => {
  const newDate = new Date(date)
  const year = newDate.getFullYear()
  const month = (newDate.getMonth() + 1).toString().padStart(2, "0") // +1, так как месяцы начинаются с 0
  const day = newDate.getDate().toString().padStart(2, "0")

  return `${year}-${month}-${day}`
}

export const BookingMobileForms = ({
  active,
  setActive,
  onSubmit,
  timeToMinutes,
  bookingData,
  isLoading,
  oneBookingData,
  oneBookingLoading,
  edit,
  editServices,
}) => {
  const {
    register,
    handleSubmit,
    setValue,
    formState: { errors },
    watch,
    setError,
    clearErrors,
    control,
    reset,
  } = useForm()
  const { statuses } = useSelector((state) => state.statusesSliceReducer)
  const [deleteServices, { isLoading: deleteServicesLoading }] =
    useDeleteServicesMutation()

  const [selectedServicesAndMasters, setSelectedServicesAndMasters] = useState(
    []
  )
  const [statusClass, setStatusClass] = useState("")
  const [freeTime, setFreeTime] = useState([])
  const [services, setServices] = useState([])
  const [masters, setMasters] = useState([])
  const [hasError, setHasError] = useState(false)
  const [formStatus, setFormStatus] = useState("new") // Изначально статус "новая запись"

  // // Функция для изменения статуса формы
  // const changeFormStatus = (status) => {
  //   setFormStatus(status);
  // };

  const bookingStatuses = statuses.filter(
    (item) =>
      item.name === "В ожидании" ||
      item.name === "Отменено" ||
      item.name === "Оплачено" ||
      item.name === "Подтверждён" ||
      item.id === 8
  )

  useEffect(() => {
    if (!oneBookingLoading && oneBookingData) {
      const services = oneBookingData?.booking?.services?.map((item) => ({
        service_id: item?.id,
        master_id: oneBookingData?.booking?.master?.id,
        master_name: oneBookingData?.booking?.master?.name,
        master_surname: oneBookingData?.booking?.master?.surname,
        price: item?.price,
        duration: item?.duration,
        service_name: item?.name,
      }))
      setSelectedServicesAndMasters([...services])
    }
  }, [oneBookingData, oneBookingLoading])

  const statusId = watch("status_id")

  useEffect(() => {
    setTimeout(() => {
      if (statusId === "3" || statusId === 3) {
        setStatusClass("text-[#991B1B] bg-[#FEE2E2]")
      } else if (statusId === "4" || statusId === 4) {
        setStatusClass("text-[#92400E] bg-[#FEF3C7]")
      } else if (statusId === "6" || statusId === 6) {
        setStatusClass("bg-[#D1FAE5] text-[#065F46]")
      } else if (statusId === "8" || statusId === 8) {
        setStatusClass("bg-[#edf1f7]")
      } else {
        setStatusClass("text-[#92400E] bg-[#FEF3C7]")
      }
    }, 10)
  }, [statusId])

  useEffect(() => {
    if (Object.keys(errors).length > 0) {
      setHasError(true) // Устанавливаем состояние при наличии ошибок
    } else {
      setHasError(false) // Сбрасываем состояние, если ошибок нет
    }
  }, [errors])

  useEffect(() => {
    // Проверяем, что время записи в oneBookingData заполнено
    if (oneBookingData?.booking?.start) {
      // Устанавливаем значение времени в форму
      setValue("time", oneBookingData.booking.start.slice(11))
    }
  }, [oneBookingData, setValue])

  // Проверка, если selectedServicesAndMasters изменён, запрашиваем новое свободное время
  useEffect(() => {
    getTime().then(() => {
      // Если есть свободное время и время начала бронирования указано, устанавливаем его
      if (freeTime.length !== 0 && oneBookingData?.booking?.start) {
        setValue("time", oneBookingData?.booking?.start.slice(11))
      }
    })
  }, [freeTime.length, oneBookingData?.booking?.start])

  useEffect(() => {
    if (bookingData?.id) {
      console.log(oneBookingData?.booking?.client?.phone)
      let startDate = oneBookingData?.booking?.start?.slice(0, 10)
      if (startDate) {
        setValue("date", new Date(startDate))
      }
      if (masters.length !== 0) {
        setTimeout(() => {
          setValue("master", oneBookingData?.booking?.master?.id)
        }, 0)
      }
      setValue("phone", `${oneBookingData?.booking?.client?.phone}`)
      setValue("name", oneBookingData?.booking?.client?.name)
      setValue("surname", oneBookingData?.booking?.client?.surname)

      setTimeout(() => {
        setValue("status_id", +oneBookingData?.booking?.status?.id)
      }, 0)
      return
    }
    setValue("status_id", 4)
  }, [oneBookingData, setValue, bookingData?.client?.id, masters.length])

  const thisService = watch("service")
  const thisMaster = watch("master")
  const thisPhone = watch("phone")
  const thisTime = watch("time")
  const thisDate = watch("date")

  const clientAutoImport = useCallback(async () => {
    if (thisPhone?.replace(/(\+992)|-/g, "").length === 9) {
      try {
        const clientData = await axios.get(
          `${currentUrl}/master/search/clients?keyword=${thisPhone?.replace(/(\+992)|-/g, "")}&salon_id=${salonId}`,
          {
            headers: {
              Authorization: accessToken,
            },
          }
        )
        if (clientData !== undefined) {
          const client = (await clientData) && clientData?.data?.data[0]
          if (client) {
            await setValue("name", client ? client.name : "")
            await setValue("surname", client ? client.surname : "")
          }
        }
      } catch (err) {
        console.error(err)
      }
    }
  }, [thisPhone, setValue])

  useEffect(() => {
    clientAutoImport()
  }, [thisPhone, clientAutoImport])

  const { data: servicesData, isLoading: servicesLoading } =
    useGetServicesQuery()
  const { data: mastersData, isLoading: mastersLoading } =
    useGetCaledarMastersQuery()

  useEffect(() => {
    if (!servicesLoading && servicesData) {
      setServices(servicesData)
    }
    if (!mastersLoading && mastersData) {
      setMasters(
        mastersData?.data?.data?.filter(
          (item) =>
            !(item?.show_calendar === "0" || item?.services?.length === 0)
        )
      )
    }
  }, [servicesLoading, servicesData, mastersLoading, mastersData])

  useEffect(() => {
    if (mastersData?.data?.length) {
      document.body.style.overflow = "hidden"
    } else {
      document.body.style.overflow = "auto"
    }

    return () => {
      document.body.style.overflow = "auto"
    }
  }, [mastersData])

  const onClose = useCallback(() => {
    setActive(false)
    setSelectedServicesAndMasters([])
    reset()
  }, [reset, setActive])

  const filteredServices = services?.filter((item) => {
    const ii = masters?.find((one) => one.id === thisMaster)
    return ii?.services?.some((obj) => obj.id === item.id)
  })

  const createBookingService = () => {
    const {
      name: masterName,
      surname: masterSurName,
      id: masterId,
    } = masters?.find((item) => item.id === thisMaster)
    const {
      id: serviceId,
      name: serviceName,
      price: servicePrice,
      duration: serviceDuration,
    } = services.find((item) => item.id === thisService)
    setSelectedServicesAndMasters([
      ...selectedServicesAndMasters,
      {
        service_id: serviceId,
        master_id: masterId,
        service_name: serviceName,
        master_name: masterName,
        master_surname: masterSurName,
        price: servicePrice,
        duration: serviceDuration,
      },
    ])
  }

  const handleAddService = () => {
    if (!thisService || !thisMaster) return
    createBookingService()
  }

  const handleDelete = async (id) => {
    try {
      await deleteServices(id).unwrap() // Разворачиваем промис для обработки ошибок
      toast.success("Услуга успешно удалена")
    } catch (error) {
      toast.error(`Ошибка удаления: ${error.message || "Попробуйте снова"}`)
    }
  }

  const handlePriceChange = async (service_id, new_price) => {
    try {
      setSelectedServicesAndMasters((prev) =>
        prev.map((service) =>
          service.service_id === service_id
            ? { ...service, price: new_price }
            : service
        )
      )

      // Send the updated price to the server
      await editServices({
        id: bookingData.id, // Assuming you have the booking ID here
        services: [
          {
            service_id,
            price: new_price,
          },
        ],
      })
    } catch (error) {
      toast.error("Не удалось обновить цену")
    }
  }

  const onSubmitBooking = async (data) => {
    const selectedDateTime = new Date(`${formatDate(data.date)}T${data.time}`)
    const now = new Date()

    const isOnlyStatusChanged =
      data.status_id !== watch("status_id") &&
      data.date === watch("date") &&
      data.time === watch("time") &&
      JSON.stringify(data.services) ===
        JSON.stringify(selectedServicesAndMasters)

    if (isOnlyStatusChanged) {
      onSubmit(data)
      return
    }

    if (isOnlyStatusChanged) {
      if (selectedDateTime < now) {
        toast.error(
          "Выбранное время уже прошло. Пожалуйста, выберите другое время."
        )
      }
      onSubmit(data)
      return
    }

    const newDate = formatDate(data.date)
    data.start_date = `${newDate} ${data.time}`

    delete data.date
    delete data.time

    data.services = selectedServicesAndMasters?.map((item) => ({
      service_id: item.service_id,
      master_id: item.master_id,
      price: item.price,
      duration: timeToMinutes(item.duration),
    }))

    const isTimeAvailable = await getTime()
    if (isTimeAvailable === false) {
      return
    }

    const endTime = calculateEndTime(data.time, selectedServicesAndMasters)
    const master = masters.find((m) => m.id === data.master)

    const masterEndTime = master?.end_time

    if (endTime > masterEndTime) {
      toast.error(
        `Время бронирования выходит за пределы рабочего времени мастера. Рабочее время до ${masterEndTime}`
      )

      return
    }

    onSubmit(data)
  }

  const sum = selectedServicesAndMasters?.reduce((acc, val) => {
    return acc + +val?.price
  }, 0)

  const getTime = async () => {
    try {
      if (selectedServicesAndMasters.length !== 0 && thisDate && thisMaster) {
        // const duration = selectedServicesAndMasters.map
        const totalMinutes = selectedServicesAndMasters.reduce((acc, obj) => {
          const [hours, minutes] = obj.duration.split(":").map(Number)
          return acc + hours * 60 + minutes
        }, 0)

        // Преобразование обратно в формат "HH:mm"

        // Форматирование результатов
        const newDate = formatDate(thisDate)
        const res = await axios.get(
          `${currentUrl}/master/bookings/getAvailableTimeSlots/${thisMaster}/${totalMinutes}/${newDate}`,
          {
            headers: {
              Authorization: `${accessToken}`,
            },
          }
        )
        setFreeTime(res.data)

        if (res.error) {
          throw new Error(res.error.data.message)
        }

        clearErrors("date")
        return true
      }
    } catch (e) {
      console.error(e)
      toast.error(e.response.data.message)
      return false
    }
  }
  //
  useEffect(() => {
    setTimeout(() => {
      if (freeTime.length !== 0 && oneBookingData?.booking?.start) {
        setValue("time", oneBookingData?.booking?.start.slice(11))
      }
    }, 0)
  }, [freeTime.length, oneBookingData?.booking?.start])

  useEffect(() => {
    getTime().then(() => {
      if (freeTime.length !== 0 && oneBookingData?.booking?.start) {
        setValue("time", oneBookingData?.booking?.start.slice(11))
      }
    })
  }, [freeTime.length, oneBookingData?.booking?.start])

  useEffect(() => {
    getTime()
  }, [thisDate, thisMaster, selectedServicesAndMasters])

  function calculateEndTime(startTime, services) {
    let totalEndMinutes = startTime
      ?.split(":")
      ?.reduce((total, part) => total * 60 + parseInt(part, 10), 0)

    for (const service of services) {
      const [durationHour, durationMinute] = service?.duration
        ?.split(":")
        .map(Number)
      totalEndMinutes += durationHour * 60 + durationMinute
    }

    const endHour = Math.floor(totalEndMinutes / 60)
    const endMinute = totalEndMinutes % 60

    const endTime = `${endHour?.toString()?.padStart(2, "0")}:${endMinute?.toString()?.padStart(2, "0")}`
    return endTime
  }

  const end = calculateEndTime(thisTime, selectedServicesAndMasters)

  return (
    <Modal
      active={active}
      setActive={setActive}
      className='   max-h-[110vh] h-full overscroll-contain  z-50  '
    >
      <div className=' h-full no-scrollbar -mt-5 overflow-y-auto pb-4'>
        <form
          onSubmit={handleSubmit(onSubmitBooking)}
          
          className='sm:max-w-[800px] w-full flex-grow  overflow-y-auto  flex justify-between flex-col  pb-8   sm:overflow-hidden no-scrollbar'
        >
          {oneBookingLoading ? (
            <div className='w-full min-h-[300px] flex justify-center items-center'>
              <TableSpinner />
            </div>
          ) : (
            <>
              <div className=' flex justify-between items-center pt-6   sticky top-0 z-50 bg-white w-full sm:w-auto mx-auto sm:mx-[0]'>
                <p className='title'>
                  {bookingData?.id ? "Редактировать" : "Добавить"} запись
                </p>
                <button onClick={onClose} className='h-fit'>
                  <Close
                    sx={{
                      color: "gray.main",
                      "&:hover": { color: "gray.dark" },
                    }}
                  />
                </button>
              </div>
              <div
                className='
        flex flex-col h-full justify-between
        [&>div>label]:flex [&>div>label]:flex-col
        [&>label>span]:mb-1 text-start
        [&>label]:mt-2 sm:[&>label]:mt- [&>label>input]:h-12 [&>label]:self-end pt-0
        '
                id='booking-form-modal'
              >
                <div className='grid  sm:grid-cols-3 mt-6 mb-4 sm:mb-6 [&>label]:mt-2 sm:[&>label]:mt-[-10px] gap-6 sm:gap-0 '>
                  <label className='label-base relative sm:mr-4'>
                    <span>
                      Номер телефона<span className='text-red-500'>*</span>
                    </span>
                    <Controller
                      control={control}
                      name='phone'
                      rules={{
                        required: "Поле обязательно для заполнения",
                        validate: (value) =>
                          value.replace(/(\+992)|-/g, "").length >= 9 ||
                          "Номер телефона должен содержать не менее 9 цифр",
                      }}
                      render={({ field }) => (
                        <PhoneInput
                          disabled={bookingData?.id}
                          {...field}
                          error={errors.phone?.message}
                        />
                      )}
                    />
                    {errors.phone && (
                      <ErrorMsg title='Заполните это поле'></ErrorMsg>
                    )}
                  </label>

                  <label className='label-base sm:mr-2 relative '>
                    <span>
                      Имя<span className='text-red-500'>*</span>
                    </span>
                    <input
                      onKeyPress={(e) => {
                        const char = String.fromCharCode(e.keyCode || e.which)
                        if (!/^[A-Za-zА-Яа-яЁё]$/.test(char)) {
                          e.preventDefault()
                        }
                      }}
                      onInput={(e) => {
                        const value = e.target.value
                        e.target.value = value.replace(/[^a-zA-Zа-яА-ЯёЁ]/g, "")
                      }}
                      disabled={bookingData?.id}
                      {...register("name", {
                        required: "Заполните это поле",
                      })}
                      type='text'
                      placeholder='Введите имя клиента...'
                      className='inputOutlined'
                    />
                    {errors.name && (
                      <ErrorMsg title='Заполните это поле'></ErrorMsg>
                    )}
                  </label>

                  <label className='label-base relative sm:ml-2 pb-4'>
                    <span>Фамилия</span>
                    <input
                      onKeyPress={(e) => {
                        const char = String.fromCharCode(e.keyCode || e.which)
                        if (!/^[A-Za-zА-Яа-яЁё]$/.test(char)) {
                          e.preventDefault()
                        }
                      }}
                      onInput={(e) => {
                        const value = e.target.value
                        e.target.value = value.replace(/[^a-zA-Zа-яА-ЯёЁ]/g, "")
                      }}
                      disabled={bookingData?.id}
                      {...register("surname", {
                        minLength: {
                          value: 2,
                          message: "Фамилия должна содержать не менее 2 букв",
                        },
                      })}
                      placeholder='Введите фамилию клиента...'
                      color={"secondary"}
                      type='text'
                      className='inputOutlined'
                    />
                    {errors.surname && (
                      <ErrorMsg title={errors.surname.message}></ErrorMsg>
                    )}
                  </label>
                </div>
              </div>

              <div className='flex-col  sm:flex-row gap-7 sm:gap-0 sm:col-span-3 flex justify-between items-end [&>label]:w-full [&>label]:flex [&>label]:flex-col mt-4 sm:mt-[0] '>
                <label className='hidden label-base sm:mr-2 w-full sm:w-[315px]'>
                  <span className=''>Специалист</span>
                  <Controller
                    name='master'
                    control={control}
                    render={({ field }) => (
                      <SelectContained
                        value={field.value || ""}
                        disabled={bookingData?.id}
                        {...field}
                        defaultValue={
                          bookingData?.master_id
                            ? bookingData?.master_id
                            : bookingData?.master?.id
                        }
                        className='mt-1 w-full sm:w-[310px]'
                      >
                        {/* <MenuItem value={field.value || ""} hidden></MenuItem> */}
                        {masters?.length !== 0 ? (
                          masters.map((item, index) => (
                            <MenuItem value={item?.id} key={index}>
                              {item?.name} {item?.surname}
                            </MenuItem>
                          ))
                        ) : (
                          <MenuItem value='' disabled>
                            <p className='menuItemText'>
                              Специалисты не добавлены, <br /> перейдите в
                              раздел «Специалисты» и добавьте их
                            </p>
                          </MenuItem>
                        )}
                      </SelectContained>
                    )}
                  />
                </label>

                <label className='label-base sm:w-[315px] w-full sm:mr-2'>
                  <span className=''>Услуги</span>
                  <Controller
                    name='service'
                    control={control}
                    render={({ field }) => (
                      <SelectContained
                        value=''
                        disabled={bookingData?.id}
                        defaultValue={
                          bookingData?.services[0][0]?.id
                            ? bookingData?.services[0][0]?.id
                            : bookingData?.services[0]?.id
                        }
                        {...field}
                        className='mt-1 w-full sm:w-[310px]'
                      >
                        {/* <MenuItem value='' hidden></MenuItem> */}

                        {filteredServices.length !== 0 ? (
                          filteredServices.map((item, index) => (
                            <MenuItem value={item?.id || ""} key={index}>
                              <p className='menuItemText'>{item?.name}</p>
                            </MenuItem>
                          ))
                        ) : (
                          <MenuItem value='' disabled>
                            <p className='menuItemText'>
                              Услуги не добавлены, перейдите в <br /> раздел
                              «Услуги» и добавьте их
                            </p>
                          </MenuItem>
                        )}
                      </SelectContained>
                    )}
                  />
                </label>

                <div className='w-full   sm:w-auto sm:ml-2 mt-4 sm:mt-0'>
                  <button
                    disabled={
                      (selectedServicesAndMasters[0] &&
                        selectedServicesAndMasters[0]?.master_id !==
                          thisMaster) ||
                      bookingData?.id
                    }
                    type='button'
                    className={
                      (selectedServicesAndMasters[0] &&
                        selectedServicesAndMasters[0]?.master_id !==
                          thisMaster) ||
                      bookingData?.id
                        ? "opacity-50 btnFilled h-full w-full"
                        : "opacity-100 btnFilled h-full w-full bg-white sm:bg-[#EE6F42] text-black sm:text-white border-2 border-[#A4AFBD] "
                    }
                    onClick={handleAddService}
                  >
                    Добавить
                  </button>
                </div>
              </div>

              <div
                className=' max-w-[800px] sm:min-h-[140px]  sm:max-h-[140px]   sm:h-full
                sm:overflow-x-auto  mt-2'
              >
                <div className='overflow-auto no-scrollbar '>
                  <div
                    className={`sm:grid flex flex-row   w-[850px] sm:w-auto border-b-2 border-[#EDEDED] py-4   gap-32  sm:gap-3  sm:grid-cols-5 sm:py-4 text-lg    [&>p]:pr-2 sm:text-center`}
                  >
                    {titles.map((item, index) => (
                      <p
                        key={index}
                        className='last:text-end whitespace-nowrap sm:overflow-hidden sm:w-auto     text-sm label-base'
                      >
                        {item}
                      </p>
                    ))}
                  </div>
                  {selectedServicesAndMasters.length > 0 ? (
                    <div className=' flex sm:block flex-col w-[850px] sm:w-auto'>
                      <SelectedServiceItem
                        id={bookingData?.id}
                        services={selectedServicesAndMasters}
                        handleDeleteMaster={(index) => {
                          setSelectedServicesAndMasters((data) => [
                            ...data.slice(0, index),
                            ...data.slice(index + 1),
                          ])
                        }}
                        onPriceChange={handlePriceChange}
                        selectedServicesAndMasters={selectedServicesAndMasters}
                        setSelectedServicesAndMasters={
                          setSelectedServicesAndMasters
                        }
                        editServices={editServices}
                      />
                    </div>
                  ) : (
                    <div className='w-full flex flex-col items-center justify-center py-[50px]'>
                      <p className='text-[16px] font-semibold '>
                        Добавьте услугу
                      </p>
                    </div>
                  )}
                </div>
              </div>

              <div className='col-span-3 flex flex-col sm:flex-row gap-3 sm:gap-0 justify-between [&>label]:w-full [&>label]:flex [&>label]:flex-col mt-6 sm:mt-2'>
                <label className='label-base sm:mr-2'>
                  <span>Дата записи</span>
                  <Controller
                    control={control}
                    rules={{ required: "Заполните это поле" }}
                    name='date'
                    render={({ field }) => (
                      <DatePicker
                        showIcon
                        minDate={new Date()}
                        selected={field.value}
                        onChange={(date) => field.onChange(date)}
                        placeholderText='мм.дд.гггг'
                        dateFormat='dd-MM-yyyy'
                        style={{ paddingLeft: "20px" }}
                        className='inputOutlined mt-1 w-full text-left !pl-10'
                        icon={
                          <div className='mt-[9px] mr-4'>
                            <svg
                              width='24'
                              height='24'
                              viewBox='0 0 24 24'
                              fill='none'
                              xmlns='http://www.w3.org/2000/svg'
                            >
                              <g clipPath='url(#clip0_8637_17044)'>
                                <path
                                  d='M17 3H21C21.2652 3 21.5196 3.10536 21.7071 3.29289C21.8946 3.48043 22 3.73478 22 4V20C22 20.2652 21.8946 20.5196 21.7071 20.7071C21.5196 20.8946 21.2652 21 21 21H3C2.73478 21 2.48043 20.8946 2.29289 20.7071C2.10536 20.5196 2 20.2652 2 20V4C2 3.73478 2.10536 3.48043 2.29289 3.29289C2.48043 3.10536 2.73478 3 3 3H7V1H9V3H15V1H17V3ZM15 5H9V7H7V5H4V9H20V5H17V7H15V5ZM20 11H4V19H20V11Z'
                                  fill='#A4AFBD'
                                />
                              </g>
                              <defs>
                                <clipPath id='clip0_8637_17044'>
                                  <rect width='24' height='24' fill='white' />
                                </clipPath>
                              </defs>
                            </svg>
                          </div>
                        }
                      />
                    )}
                  />

                  {errors.date && (
                    <p className='text-red'>{errors?.date?.message}</p>
                  )}
                </label>

                <label className='label-base relative sm:ml-2'>
                  <span>Время записи</span>
                  <Controller
                    control={control}
                    name='time'
                    InputLabelProps={{
                      shrink: true,
                    }}
                    disabled={formStatus === "edit"}
                    rules={{
                      required: "Заполните это поле",
                    }}
                    render={({ field }) => (
                      <div className='relative w-full'>
                        <div className='absolute left-2 top-[14px] z-10 '>
                          <AccessTimeIcon color='disabled' />
                        </div>
                        <SelectContained
                          defaultValue={oneBookingData?.booking?.start?.slice(
                            11
                          )}
                          {...field}
                          time
                          renderValue
                          className='mt-[3px]'
                        >
                          {oneBookingData?.booking?.start && (
                            <MenuItem
                              selected
                              value={oneBookingData?.booking?.start?.slice(11)}
                            >
                              {oneBookingData?.booking?.start?.slice(11)}
                            </MenuItem>
                          )}
                          {freeTime.length === 0 ? (
                            <MenuItem disabled>Нет свободного времени</MenuItem>
                          ) : (
                            freeTime?.map((item, index) => (
                              <MenuItem key={index} value={item}>
                                {item}
                              </MenuItem>
                            ))
                          )}
                        </SelectContained>
                      </div>
                    )}
                  />
                  {errors.time && (
                    <p className='text-red'>{errors.time.message}</p>
                  )}
                </label>
              </div>

              <div className='flex items-center justify-between w-full mt-6 sm:mt-2 '>
                <div>
                  <p className='font-medium text-[18px]'>Итог:</p>
                  <p className='text-[#737C86]'>Продолжительность:</p>
                </div>
                <div className='text-right'>
                  <p className='font-medium text-[18px]'>{sum} TJS</p>
                  <p className='text-[#737C86]'>
                    {thisTime || ""} - {end === "NaN:NaN" ? "" : end}
                  </p>
                </div>
              </div>

              <div className='w-full flex flex-col sm:flex-row h-fit justify-between mt-8 sm:mt-2  gap-4  '>
                <select
                  disabled={
                    oneBookingData?.booking?.status?.id === 3 ||
                    oneBookingData?.booking?.status?.id === 6
                  }
                  {...register("status_id", {
                    required: "Выберите статус записи",
                  })}
                  className={`inputOutlined w-full sm:w-60 cursor-pointer h-12 py-3 ${statusClass}`}
                >
                  {bookingStatuses.map((item) => (
                    <option
                      className={`${item?.id === 3 ? "text-[#991B1B] bg-[#FEE2E2]" : item?.id === 4 ? "text-[#92400E] bg-[#FEF3C7]" : item?.id === 6 ? "bg-[#D1FAE5] text-[#065F46]" : item?.id === 8 ? "bg-[#edf1f7]" : ""}`}
                      key={item?.id}
                      value={item?.id}
                    >
                      {item?.name}
                    </option>
                  ))}
                </select>

                <div className='flex [&>button]:px-9 justify-between   '>
                  <button
                    onClick={onClose}
                    type='button'
                    className='btnOutlined mr-2'
                  >
                    Отмена
                  </button>
                  <button
                    disabled={
                      oneBookingData?.booking?.status?.id === 3 || isLoading
                    }
                    type='submit'
                    // disabled={editLoading || postLoading}
                    className={
                      oneBookingData?.booking?.status?.id === 3 || isLoading
                        ? "btnFilled disabled:bg-darkOrange ml-2 opacity-50"
                        : "btnFilled disabled:bg-darkOrange ml-2"
                    }
                  >
                    Сохранить
                  </button>
                </div>
              </div>
            </>
          )}
        </form>
      </div>
    </Modal>
  )
}