import React, { useEffect, useState } from "react"
import { useFormik } from "formik"
import * as yup from "yup"
import Resizer from "react-image-file-resizer"
import { AddMasterService } from "features/masters/ui/AddMasterService"

import { PhoneInput } from "shared/ui/input/PhoneInput"
import { schemes } from "widgets/auth/validations"

import { RadioBtn } from "shared/ui/button/RadioBtn"
import { MyCheckbox } from "shared/ui/button/MyCheckbox"
import { ErrorMsg } from "shared/ui/input/ErrorMsg"
import { SectionsLayout } from "shared/ui/modal/SectionsLayout"
import { DeleteBtn } from "shared/ui/button/DeleteBtn"
import {
  useGetMastersQuery,
  usePostAvatarMutation,
  usePostPortfolioMutation,
} from "pages/masters/slice"
import { useDeleteMasterServiceMutation } from "pages/masters/slice"
import { TableSpinner } from "shared/ui/dataTable/spinner"
import {
  useGetCategoriesQuery,
  useGetCategoriesServicesQuery,
} from "../../pages/categories/slice"
import { ServiceTitles } from "../services/ServiceTitles"
import { Button, MenuItem } from "@mui/material"
import { CloudUpload, PhotoCameraOutlined } from "@mui/icons-material"
import { useDropzone } from "react-dropzone"
import CloseIcon from "@mui/icons-material/Close"
import { formatPhone } from "../../pages/auth/slice"
import { toast } from "sonner"
import { SelectContained } from "shared/ui/select/SelectContained"
import { Controller } from "react-hook-form"
import DaySchedule from "shared/ui/input/daySchedule"
import { TimeInput } from "shared/ui/input/TimeInput"
import { format, isValid } from "date-fns"

const isMobile = window.screen.width < 500

const customEmailValidationSchema = yup
  .string()
  .email("Введите корректный email")

const servicesTitles = ["Услуга", "Цена", "Продолжительность", "Еще"]

export const MasterMobileForms = ({
  active,
  setActive,
  masterData = {},
  onSubmit,
  selectedPhoto,
  setSelectedPhoto,
  selectedPortfolio,
  setSelectedPortfolio,
  reqLoading,
  avatarLoading,
  sections,
  selectedSection,
  setEmailError,
  setSelectedSection,
  emailError,
  mastersRefetch,
  reqError,
  onAvatarDrop,
}) => {
  const { data: categories } = useGetCategoriesServicesQuery({
    limit: 1000,
    pageSize: 10000,
  })
  const [deleteService] = useDeleteMasterServiceMutation()
  const { id: masterId } = masterData

  // Function to update a specific schedule
  const onDrop = (acceptedFiles) => {
    if (acceptedFiles.length > 0) {
      acceptedFiles.forEach((file) => {
        Resizer.imageFileResizer(
          file,
          800, // Ширина
          800, // Высота
          "JPEG,PNG,JPG,webp,svg", // Формат
          100, // Качество
          0, // Оставляет ориентацию без изменений
          (uri) => {
            setSelectedPortfolio((prev) => [...prev, uri]) // Добавляем обработанное изображение
          },
          "file" // Тип возвращаемых данных (file, base64, blob)
        )
      })
    }
  }

  const { getRootProps: avatarRootProps, getInputProps: avatarInputProps } =
    useDropzone({ onDrop: onAvatarDrop })

  const {
    getRootProps: portfolioRootProps,
    getInputProps: portfolioInputProps,
  } = useDropzone({
    accept: "image/*",
    multiple: true,
    onDrop
  })

  function changeSection(section) {
    setSelectedSection(section)
  }

  const [services, setServices] = useState(masterData.services ?? [])

  const initialWorkSchedules = [
    {
      id: 1,
      day: "Понедельник",
      work: 0,
      start_time: "",
      end_time: "",
      launch_start_time: "",
      launch_end_time: "",
    },
    {
      id: 2,
      day: "Вторник",
      work: 0,
      start_time: "",
      end_time: "",
      launch_start_time: "",
      launch_end_time: "",
    },
    {
      id: 3,
      day: "Среда",
      work: 0,
      start_time: "",
      end_time: "",
      launch_start_time: "",
      launch_end_time: "",
    },
    {
      id: 4,
      day: "Четверг",
      work: 0,
      start_time: "",
      end_time: "",
      launch_start_time: "",
      launch_end_time: "",
    },
    {
      id: 5,
      day: "Пятница",
      work: 0,
      start_time: "",
      end_time: "",
      launch_start_time: "",
      launch_end_time: "",
    },
    {
      id: 6,
      day: "Суббота",
      work: 0,
      start_time: "",
      end_time: "",
      launch_start_time: "",
      launch_end_time: "",
    },
    {
      id: 7,
      day: "Воскресенье",
      work: 0,
      start_time: "",
      end_time: "",
      launch_start_time: "",
      launch_end_time: "",
    },
  ]

  const [workSchedules, setWorkSchedules] = useState(() => {
    if (masterData.work_schedules && masterData.work_schedules.length > 0) {
      return masterData.work_schedules
    }
    return initialWorkSchedules
  })

  const handleWorkSchedulesChange = (id, key, value) => {
    setWorkSchedules((prevSchedules) =>
      prevSchedules.map((schedule) =>
        schedule.id === id ? { ...schedule, [key]: value } : schedule
      )
    )
  }

  const deleteOnePhoto = (item) => {
    setSelectedPortfolio(selectedPortfolio.filter((obj) => obj !== item))
  }

  // personal info section form
  const {
    values: persInfoValues,
    handleChange: persinfoHandleChange,
    handleReset: persInfoOnReset,
    handleSubmit: persInfoOnSubmit,
    errors: persInfoErrors,
    touched: persInfoTouched,
    setFieldValue: setPersInfoValue,
  } = useFormik({
    initialValues: {
      name: masterData.name || "",
      surname: masterData.surname || "",
      phone: `+992${masterData.phone}`,
      email: masterData.email || "",
      position: masterData.position || "",
      gender: masterData.gender || "",
      description: masterData.description || "",
    },
    validationSchema: yup.object().shape({
      name: schemes.name,
      surname: schemes.surname,
      phone: masterData?.phone?.length === 0 && schemes.phone,
      email: customEmailValidationSchema,
      position: yup
        .string("")
        .required("Поле является обязательным!")
        .nullable(),
    }),
    onSubmit: () => {
      if (masterData.mode === "edit") {
        finalSubmit()
      } else {
        setEmailError(false)
        changeSection(sections[1])
      }
    },
    onReset: () => {
      setActive(false)
    },
  })

  // settings section form
  const {
    values: settingsValues,
    handleSubmit: settingsOnSubmit,
    handleReset: settingsOnReset,
    setFieldValue: settingsSetValue,
  } = useFormik({
    initialValues: {
      // password: masterData.password,
      show_calendar: masterData.show_calendar,
    },
    onSubmit: () => {
      if (masterData.mode === "edit") {
        finalSubmit()
      } else {
        changeSection(sections[2])
      }
    },
    onReset: () => {
      changeSection(sections[0])
    },
  })

  // services section
  const [activeAddService, setActiveAddService] = useState(false)

  function activeAddServiceModal() {
    setActiveAddService(true)
  }

  function updateServices(list) {
    setServices(list)
  }

  async function removeService(newService) {
    if (masterData?.services?.some((item) => item.id === newService.id)) {
      try {
        await deleteService({ masterId: masterId, id: newService.id })
        setServices(
          services.filter((oldService) => oldService.id !== newService.id)
        )
        mastersRefetch()
        toast.success("Услуга успешно удалена!", { position: "bottom-right" })
      } catch (err) {
        toast.error("Ошибка при удалении услуги!")
      }
    } else {
      setServices(
        services.filter((oldService) => oldService.id !== newService.id)
      )
    }
  }

  const {
    values: socialNetValues,
    handleChange: socialNetOnChange,
    handleSubmit: socialNetOnSubmit,
    handleReset: socialNetOnReset,
  } = useFormik({
    initialValues: {
      website: masterData.website,
      facebook: masterData.facebook,
      instagram: masterData.instagram,
    },
    onSubmit: () => {
      changeSection(sections[4])
    },
    onReset: () => changeSection(sections[3]),
  })

  const [postAvatarQuery, { isLoading: isAvatarLoading }] =
    usePostAvatarMutation()
  const [avatar, setAvatar] = useState(masterData?.avatar)
  const [photoError, setPhotoError] = useState(false)

  const [portfolio] = useState(masterData.portfolio)
  const [postPortfolioQuery, { isLoading: isPortfolioLoading }] =
    usePostPortfolioMutation()

  const handleWorkChange = (dayId, checked) => {
    const updatedSchedules = workSchedules.map((schedule) =>
      schedule.id === dayId ? { ...schedule, work: checked ? 1 : 0 } : schedule
    )
    setWorkSchedules(updatedSchedules)
  }

  function finalSubmit() {
    const photos = portfolio.length ? [...portfolio.map(({ id }) => id)] : []
    if (avatar.id) {
      photos.push(avatar.id)
    }
    const formattedPhone = formatPhone(persInfoValues.phone)

    const workSchedulesToSubmit =
      workSchedules.length > 0
        ? workSchedules
        : [
            {
              id: 1,
              day: "",
              work: 0,
              start_time: "",
              end_time: "",
              launch_start_time: "",
              launch_end_time: "",
            },
          ]

    const formatTime = (time) => {
      if (!time) return null

      const parts = time.split(":")
      if (parts.length === 2) {
        return `${parts[0].padStart(2, "0")}:${parts[1].padStart(2, "0")}`
      }
      return `${parts[0].padStart(2, "0")}:00`
    }

    const formattedWorkSchedules = workSchedulesToSubmit.map((schedule) => ({
      ...schedule,
      start_time: formatTime(schedule.start_time),
      end_time: formatTime(schedule.end_time),
      launch_start_time: schedule.launch_start_time
        ? formatTime(schedule.launch_start_time)
        : null,
      launch_end_time: schedule.launch_end_time
        ? formatTime(schedule.launch_end_time)
        : null,
      work: schedule.work,
    }))

    const result = {
      id: masterData.id,
      name: persInfoValues.name,
      surname: persInfoValues.surname,
      phone: formattedPhone,
      email: persInfoValues.email,
      position: persInfoValues.position,
      photo: photos,
      gender: persInfoValues.gender,
      description: persInfoValues.description,
      show_calendar: settingsValues.show_calendar,
      website: socialNetValues.website,
      facebook: socialNetValues.facebook,
      instagram: socialNetValues.instagram,
      services: services,
      work_schedules: formattedWorkSchedules,
    }

    try {
      onSubmit(result)
    } catch (error) {
      console.log(reqError.data.message, "error")
    }
  }

  function formatDuration(duration) {
    const [hours, minutes, seconds] = duration.split(":").map(Number)

    // Преобразуем часы и минуты в двузначные числа
    const formattedHours = hours.toString().padStart(2, "0")
    const formattedMinutes = minutes.toString().padStart(2, "0")

    return `${formattedHours}:${formattedMinutes}`
  }

  function handleClickWorkSchedule() {
    if (masterData.mode === "edit") {
      finalSubmit()
    } else {
      if (
        settingsValues?.show_calendar === "1" &&
        !workSchedules.find((schedule) => schedule.work === 1)
      ) {
        toast.error("Заполните график работы специалиста")
        return
      }
      changeSection(sections[4])
    }
  }

  return (
    <SectionsLayout
      active={active}
      setActive={setActive}
      headerTitle={
        masterData?.id ? "Редактировать специалиста" : "Добавить специалиста"
      }
      sections={sections}
      currentSection={selectedSection}
      onSelectSection={masterData?.id ? changeSection : () => null}
      className=' h-[100vh] -mt-10  overflow-hidden  '
      headerClassName={"mb-5 sticky left-0 right-0   top-10 bg-white"}
    >
      {/* Personal info */}
      {selectedSection === sections[0] && (
        <div className='h-full overflow-y-auto mt-10 no-scrollbar py-6'>
          <div className='flex  sm:flex-row flex-col gap-10  '>
            {/* avatar */}
            <div className='flex sm:flex-col flex-row gap-5 -mt-5 sm:gap-0 relative h-fit'>
              <div className='w-36 sm:mb-4'>
                {isAvatarLoading ? (
                  <div className='w-36 h-36 flex items-center justify-center'>
                    <TableSpinner />
                  </div>
                ) : (
                  <img
                    src={
                      selectedPhoto
                        ? URL.createObjectURL(selectedPhoto)
                        : avatar[avatar?.length - 1]?.url ||
                          `//static.vecteezy.com/system/resources/previews/005/276/776/original/logo-icon-person-on-white-background-free-vector.jpg`
                    }
                    className='w-full h-36 object-cover rounded-lg'
                    alt='avatar'
                  />
                )}
              </div>
              <div>
                <div
                  {...avatarRootProps()}
                  className='mt-20  w-[170px] sm:w-auto sm:mt-[0]'
                >
                  <Button
                    disabled={isAvatarLoading}
                    fullWidth
                    className='w-full sm:w-auto '
                    startIcon={<PhotoCameraOutlined />}
                    sx={{
                      fontWeight: 500,
                      "&:hover": {
                        bgcolor: "secondary.main",
                        color: "white",
                      },
                    }}
                    variant='outlined'
                    color='secondary'
                  >
                    <input
                      {...avatarInputProps()}
                      id='upload'
                      hidden
                      accept='image/*'
                      type='file'
                    />
                    {masterData.mode === "edit" ? "Изменить" : "Добавить"}
                  </Button>
                </div>
              </div>
              {photoError && (
                <ErrorMsg
                  className='sm:-bottom-16'
                  title={"Не удалось загрузить фото"}
                />
              )}
            </div>
            {/* forms */}
            <div
              className='
              w-full sm:ml-6 sm:grid sm:grid-cols-2 h-full   
              [&>label]:flex [&>label]:flex-col [&>label:nth-child(1n+3)]:mt-8
          '
            >
              <label className='label-base sm:mr-5 relative'>
                Имя*
                <input
                  name='name'
                  type='text'
                  onKeyPress={(e) => {
                    const char = String.fromCharCode(e.keyCode || e.which)
                    if (!/^[A-Za-zА-Яа-яЁё]$/.test(char)) {
                      e.preventDefault()
                    }
                  }}
                  placeholder='Введите имя'
                  value={persInfoValues.name ?? ""}
                  onChange={persinfoHandleChange}
                  className='inputOutlined'
                />
                {persInfoTouched.name && persInfoErrors.name && (
                  <ErrorMsg className='-bottom-2' title={persInfoErrors.name} />
                )}
              </label>

              <label className='label-base relative mt-6 sm:mt-[0]'>
                Фамилия
                <input
                  name='surname'
                  type='text'
                  placeholder='Введите фамилию'
                  value={persInfoValues.surname ?? ""}
                  onChange={persinfoHandleChange}
                  className='inputOutlined'
                  onKeyPress={(e) => {
                    const char = String.fromCharCode(e.keyCode || e.which)
                    if (!/^[A-Za-zА-Яа-яЁё]$/.test(char)) {
                      e.preventDefault()
                    }
                  }}
                />
                {persInfoTouched.surname && persInfoErrors.surname && (
                  <ErrorMsg
                    className='-bottom-2'
                    title={persInfoErrors.surname}
                  />
                )}
              </label>

              <label className='label-base sm:mr-5 relative'>
                Электронная почта
                <input
                  name='email'
                  type='email'
                  placeholder='example@gmail.com'
                  value={persInfoValues.email ?? ""}
                  onChange={persinfoHandleChange}
                  className='inputOutlined'
                />
                {persInfoTouched.email && persInfoErrors.email && (
                  <ErrorMsg
                    className='-bottom-2'
                    title={persInfoErrors.email}
                  />
                )}
                <div>
                  {emailError && (
                    <p className='text-red -bottom-2'>
                      Пользователь с данным email уже существует
                    </p>
                  )}
                </div>
              </label>

              <label className='label-base relative'>
                Номер телефона*
                <PhoneInput
                  name='phone'
                  disabled={masterData?.id}
                  value={persInfoValues.phone ?? ""}
                  onChange={persinfoHandleChange}
                />
                {persInfoTouched.phone && persInfoErrors.phone && (
                  <ErrorMsg
                    className='-bottom-2'
                    title={persInfoErrors.phone}
                  />
                )}
              </label>

              <label className='label-base sm:mr-4 relative'>
                Должность*
                <input
                  name='position'
                  type='text'
                  placeholder='Парикмахер'
                  value={persInfoValues.position ?? ""}
                  оли
                  onChange={persinfoHandleChange}
                  className='inputOutlined'
                />
                {persInfoTouched.position && persInfoErrors.position && (
                  <ErrorMsg
                    className='-bottom-2'
                    title={persInfoErrors.position}
                  />
                )}
              </label>

              <div className='label-base pt-8 relative'>
                Пол
                <div
                  className='
                  text-darkGray text-base font-normal flex items-center mt-2
                  '
                >
                  <div className='mr-6 flex items-center'>
                    <RadioBtn
                      name='gender'
                      value='male'
                      checked={persInfoValues.gender === "male"}
                      onChange={persinfoHandleChange}
                    />
                    <span
                      onClick={() => setPersInfoValue("gender", "male")}
                      className='cursor-pointer'
                    >
                      Мужской
                    </span>
                  </div>
                  <div className='sm:mr-6 flex items-center'>
                    <RadioBtn
                      name='gender'
                      value='femele'
                      checked={persInfoValues.gender === "femele"}
                      onChange={persinfoHandleChange}
                    />
                    <span
                      onClick={() => setPersInfoValue("gender", "femele")}
                      className='cursor-pointer'
                    >
                      Женский
                    </span>
                  </div>
                </div>
                {persInfoTouched.gender && persInfoErrors.gender && (
                  <ErrorMsg title={persInfoErrors.gender} />
                )}
              </div>

              <label className='label-base col-span-2 mt-8 relative'>
                Описание
                <textarea
                  maxLength={400}
                  className='inputOutlined sm:h-20 resize-none'
                  placeholder='Описание специалиста'
                  name='description'
                  value={persInfoValues.description ?? ""}
                  onChange={persinfoHandleChange}
                />
                {persInfoTouched.description && persInfoErrors.description && (
                  <ErrorMsg title={persInfoErrors.description} />
                )}
              </label>
              <div className=' sm:text-end flex mx-auto  mt-8 pb-4 gap-9 flex-row items-center justify-end'>
                <button
                  onClick={settingsOnReset}
                  className='btnOutlined  sm:w-40 w-[150px]'
                >
                  Назад
                </button>
                <button
                  className='btnFilled sm:w-40 w-[150px]'
                  type='submit'
                  onClick={settingsOnSubmit}
                >
                  {masterData.mode === "edit" ? "Сохранить" : "Далее"}
                </button>
              </div>
            </div>
          </div>
        </div>
      )}

      {/* Settings */}
      {selectedSection === sections[1] && (
        <div className='flex flex-col mt-10 justify-between h-full '>
          <div className='flex flex-col'>
            <div className='mb-3'>
              <MyCheckbox
                name={"show_calendar"}
                value={settingsValues.show_calendar === "1"} // checked
                checked={settingsValues.show_calendar === "1"}
                onChange={() =>
                  settingsSetValue(
                    "show_calendar",
                    settingsValues.show_calendar === "0" ? "1" : "0"
                  )
                }
                inner={"Показывать специалиста в расписании"}
              />
            </div>
          </div>
          <div className=' sm:text-end flex mx-auto pb-8  gap-9 flex-row items-center justify-end'>
            <button
              onClick={settingsOnReset}
              className='btnOutlined  sm:w-40 w-[150px]'
            >
              Назад
            </button>
            <button
              className='btnFilled sm:w-40 w-[150px]'
              type='submit'
              onClick={settingsOnSubmit}
            >
              {masterData.mode === "edit" ? "Сохранить" : "Далее"}
            </button>
          </div>
        </div>
      )}

      {/* Services */}
      {selectedSection === sections[2] && (
        <>
          {!services.length ? (
            <div className='h-full flex-2 mt-10 w-full flex justify-center items-center'>
              <button
                onClick={activeAddServiceModal}
                className='h-fit btnOutlined bg-lightOrange border-none text-orange hover:bg-orange/[12%] active:bg-orange/[24%]'
              >
                Добавить услугу
              </button>
            </div>
          ) : (
            <div className='min-h-[60%] mt-10 max-h-[60%] flex flex-col overflow-auto no-scrollbar w-full'>
              <div className='mb-3 text-end'>
                <button
                  onClick={activeAddServiceModal}
                  className='h-fit btnOutlined bg-lightOrange border-none text-orange hover:bg-orange/[12%] active:bg-orange/[24%] mx-auto '
                >
                  Добавить услугу
                </button>
              </div>

              {/* table */}

              <div className='sm:h-full  sm:overflow-y-auto sm:overflow-x-hidden  '>
                <ServiceTitles
                  titles={servicesTitles}
                  className='hidden sm:grid'
                />
                {services.map((service) => (
                  <div
                    key={service.id}
                    className='min-h-[48px] sm:w-full sm:text-center flex flex-row justify-between sm:grid  box-border sm:[&>p]:my-1 sm:[&>p]:mr-1 sm:grid-cols-4 items-center gap-4 sm:gap-10 sm:justify-self-start border-b-2 border-[#E8E8E8]'
                  >
                    <p className=' truncated w-40 sm:w-auto '>{service.name}</p>
                    <p className='w-10 sm:w-auto text-right sm:text-left flex-shrink-0 mr-3  '>
                      {service.price}
                    </p>
                    <p className='w-10 sm:w-auto text-right  sm:text-left  flex-shrink-0 ml-2 sm:ml-[0]'>
                      {formatDuration(service?.duration)}
                    </p>
                    <div className='w-7 sm:w-auto  flex-shrink-0 flex justify-end   ml-3 sm:ml-[-20px] '>
                      <DeleteBtn onClick={() => removeService(service)} />
                    </div>
                  </div>
                ))}
              </div>
            </div>
          )}
          {/* submit btns */}
          <div
            className={`${services.length && "h-full"} flex items-end justify-end `}
          >
            <div className='h-fit sm:text-end flex mx-auto pb-8  gap-12 flex-row items-center justify-end   '>
              <button
                onClick={() => changeSection(sections[1])}
                className='btnOutlined  sm:w-40 w-[150px]'
              >
                Назад
              </button>
              <button
                onClick={() => {
                  masterData.mode === "edit"
                    ? finalSubmit()
                    : changeSection(
                        sections[masterData.mode === "edit" ? 4 : 3]
                      )
                }}
                className='btnFilled sm:w-40 w-[150px]'
              >
                {masterData.mode === "edit" ? "Сохранить" : "Далее"}
              </button>
            </div>
          </div>
          {activeAddService && (
            <AddMasterService
              active={activeAddService}
              setActive={setActiveAddService}
              categories={categories}
              currentServices={services}
              onSubmit={updateServices}
            />
          )}
        </>
      )}

      {selectedSection === sections[3] && (
        <>
          <div className='overflow-y-auto flex flex-col  mt-10  gap-6 pb-6 sm:pb-2 sm:min-h-[400px]  '>
            {workSchedules.map((day) => (
              <div
                key={day.id}
                className='flex   flex-col justify-center items-center   '
              >
                <span className=' flex w-full items-center'>
                  <MyCheckbox
                    name='show_calendar'
                    checked={day.work === 1}
                    onChange={(e) => handleWorkChange(day.id, e.target.checked)}
                  />

                  <p className='text-[16px] font-medium leading-[18px] w-full text-left'>
                    {day.day}
                  </p>
                </span>
                <div className='grid  grid-cols-1 sm:grid-cols-2 gap-3'>
                  <div className='grid  grid-cols-2 gap-3'>
                    <div className='flex flex-col  sm:w-[172px] relative h-fit'>
                      <p>От</p>
                      <TimeInput
                        name='start_time'
                        value={day.start_time || ""}
                        onChange={(e) =>
                          handleWorkSchedulesChange(
                            day.id,
                            "start_time",
                            e.target.value
                          )
                        }
                        required
                        disabled={day.work !== 1}
                        className='w-full '
                      />
                    </div>
                    <div className='flex flex-col  sm:w-[172px] h-fit  sm:relative'>
                      <p>До</p>
                      <TimeInput
                        name='end_time'
                        value={day.end_time || ""}
                        onChange={(e) =>
                          handleWorkSchedulesChange(
                            day.id,
                            "end_time",
                            e.target.value
                          )
                        }
                        disabled={day.work !== 1}
                        required
                        className='w-full '
                      />
                    </div>
                  </div>
                  <div className='flex flex-col sm:mt-[-16px]  '>
                    <p className='text-[14px] font-normal  leading-4 text-[#1E3148]'>
                      Перерыв
                    </p>
                    <div className='grid  grid-cols-2 gap-3 '>
                      <div className='flex flex-col  sm:w-[172px]'>
                        <p>От</p>
                        <TimeInput
                          name='lunch_start_time'
                          value={day.launch_start_time || ""}
                          onChange={(e) =>
                            handleWorkSchedulesChange(
                              day.id,
                              "launch_start_time",
                              e.target.value
                            )
                          }
                          required
                          disabled={day.work !== 1}
                          className='w-full '
                        />
                      </div>
                      <div className='flex flex-col  sm:w-[172px]'>
                        <p>До</p>
                        <TimeInput
                          name='launch_end_time'
                          value={day.launch_end_time || ""}
                          onChange={(e) =>
                            handleWorkSchedulesChange(
                              day.id,
                              "launch_end_time",
                              e.target.value
                            )
                          }
                          required
                          disabled={day.work !== 1}
                          className='w-full '
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <div />
                <div />
              </div>
            ))}
          </div>

          {/* submit btns */}
          <div className=' h-full flex items-end justify-end '>
            <div className='h-fit sm:text-end flex mx-auto pb-8  gap-9 flex-row items-center justify-end   '>
              <button
                onClick={() => changeSection(sections[2])}
                className='btnOutlined mr-5 sm:w-40 w-[150px]'
              >
                Назад
              </button>
              <button
                // onClick={() =>
                //   masterData.mode === "edit"
                //     ? finalSubmit()
                //     : changeSection(sections[4])
                // }
                onClick={handleClickWorkSchedule}
                className='btnFilled sm:w-40 w-[141px]'
              >
                {masterData.mode === "edit" ? "Сохранить" : "Далее"}
              </button>
            </div>
          </div>
        </>
      )}

      {/* Social networks */}
      {selectedSection === sections[4] && (
        <div className='relative flex flex-col mt-10 justify-between h-full '>
          <div className='w-full [&>label]:flex [&>label]:flex-col [&>label]:w-full flex sm:flex-row flex-col gap-2'>
            {/* <label className='label-base sm:mr-5'>
              Ссылка на сайт
              <input
                name='website'
                type='text'
                placeholder='https://website.com/...'
                className='inputOutlined'
                value={socialNetValues.website || ""}
                onChange={socialNetOnChange}
              />
            </label> */}

            <label className='label-base sm:mr-5'>
              Ссылка на facebook
              <input
                name='facebook'
                type='text'
                placeholder='facebook.com/nickname...'
                className='inputOutlined'
                value={socialNetValues.facebook || ""}
                onChange={socialNetOnChange}
              />
            </label>

            <label className='label-base'>
              Ссылка на instagram
              <input
                name='instagram'
                type='text'
                placeholder='instagram.com/nickname...'
                className='inputOutlined'
                value={socialNetValues.instagram || ""}
                onChange={socialNetOnChange}
              />
            </label>
          </div>

          <div className=' h-full flex items-end justify-end '>
            <div className='h-fit sm:text-end flex gap-10 flex-row items-center justify-end pb-8  '>
              <button
                type='reset'
                onClick={socialNetOnReset}
                className='btnOutlined sm:w-40 w-[150px]'
              >
                Назад
              </button>

              <button
                onClick={finalSubmit}
                disabled={reqLoading || avatarLoading}
                className={
                  reqLoading || avatarLoading
                    ? "opacity-50 btnFilled sm:w-40 w-[150px]"
                    : "opacity-100 btnFilled sm:w-40 w-[150px]"
                }
              >
                Сохранить
              </button>
            </div>
          </div>
        </div>
      )}
      {/* Portfolio */}
      {selectedSection === sections[5] && (
        <div className='flex flex-col justify-between h-full'></div>
      )}
    </SectionsLayout>
  )
}